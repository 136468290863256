// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src2998905632/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-modules-home-client-home-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/home/client-home-detail.tsx" /* webpackChunkName: "component---src-modules-home-client-home-detail-tsx" */),
  "component---src-modules-home-doctor-home-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/home/doctor-home-detail.tsx" /* webpackChunkName: "component---src-modules-home-doctor-home-detail-tsx" */),
  "component---src-modules-about-about-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/about/about-detail.tsx" /* webpackChunkName: "component---src-modules-about-about-detail-tsx" */),
  "component---src-modules-about-institutional-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/about/institutional-list.tsx" /* webpackChunkName: "component---src-modules-about-institutional-list-tsx" */),
  "component---src-modules-campaign-receptive-campaign-receptive-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/campaign-receptive/campaign-receptive-detail.tsx" /* webpackChunkName: "component---src-modules-campaign-receptive-campaign-receptive-detail-tsx" */),
  "component---src-modules-exam-exam-manual-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/exam/exam-manual-list.tsx" /* webpackChunkName: "component---src-modules-exam-exam-manual-list-tsx" */),
  "component---src-modules-exam-exam-manual-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/exam/exam-manual-detail.tsx" /* webpackChunkName: "component---src-modules-exam-exam-manual-detail-tsx" */),
  "component---src-modules-exam-exam-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/exam/exam-list.tsx" /* webpackChunkName: "component---src-modules-exam-exam-list-tsx" */),
  "component---src-modules-exam-exam-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/exam/exam-detail.tsx" /* webpackChunkName: "component---src-modules-exam-exam-detail-tsx" */),
  "component---src-modules-faq-faq-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/faq/faq-list.tsx" /* webpackChunkName: "component---src-modules-faq-faq-list-tsx" */),
  "component---src-modules-faq-faq-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/faq/faq-detail.tsx" /* webpackChunkName: "component---src-modules-faq-faq-detail-tsx" */),
  "component---src-modules-health-plan-health-plan-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/health-plan/health-plan-list.tsx" /* webpackChunkName: "component---src-modules-health-plan-health-plan-list-tsx" */),
  "component---src-modules-health-plan-health-plan-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/health-plan/health-plan-detail.tsx" /* webpackChunkName: "component---src-modules-health-plan-health-plan-detail-tsx" */),
  "component---src-modules-magazine-medical-magazines-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/magazine/medical-magazines-list.tsx" /* webpackChunkName: "component---src-modules-magazine-medical-magazines-list-tsx" */),
  "component---src-modules-news-news-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/news/news-list.tsx" /* webpackChunkName: "component---src-modules-news-news-list-tsx" */),
  "component---src-modules-news-news-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/news/news-detail.tsx" /* webpackChunkName: "component---src-modules-news-news-detail-tsx" */),
  "component---src-modules-search-search-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/search/search.tsx" /* webpackChunkName: "component---src-modules-search-search-tsx" */),
  "component---src-modules-unit-unit-list-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/unit/unit-list.tsx" /* webpackChunkName: "component---src-modules-unit-unit-list-tsx" */),
  "component---src-modules-unit-unit-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/unit/unit-detail.tsx" /* webpackChunkName: "component---src-modules-unit-unit-detail-tsx" */),
  "component---src-modules-privacy-index-jsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/privacy/index.jsx" /* webpackChunkName: "component---src-modules-privacy-index-jsx" */),
  "component---src-modules-medical-area-index-jsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/medical-area/index.jsx" /* webpackChunkName: "component---src-modules-medical-area-index-jsx" */),
  "component---src-modules-invasion-index-jsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/invasion/index.jsx" /* webpackChunkName: "component---src-modules-invasion-index-jsx" */),
  "component---src-modules-landing-pages-landing-pages-detail-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/modules/landing-pages/landing-pages-detail.tsx" /* webpackChunkName: "component---src-modules-landing-pages-landing-pages-detail-tsx" */),
  "component---src-pages-404-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fale-conosco-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/pages/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-fale-conosco-tsx" */),
  "component---src-pages-medico-cadastro-medico-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/pages/medico/cadastro-medico.tsx" /* webpackChunkName: "component---src-pages-medico-cadastro-medico-tsx" */),
  "component---src-pages-medico-fale-conosco-tsx": () => import("/codebuild/output/src2998905632/src/packages/site/src/pages/medico/fale-conosco.tsx" /* webpackChunkName: "component---src-pages-medico-fale-conosco-tsx" */)
}

