import { diactriclessKebabCase } from './string'
import { doctorUrlPrefix, getDetailPageUrl, insertDoctorPrefix, normalizeUrl } from './url'

/**
 * This dictionary is coupled with the pages folder.
 * Therefore, if you rename any file there, it should be renamed here as well
 *
 * ps: every path should start with `/` prefix
 *
 */
export const pagesPaths = {
  homeClient: {
    path: '/',
    name: `${process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? 'Cliente' : 'Cliente'}`
  },
  homeDoctor: { path: `/${doctorUrlPrefix}`, name: 'Médico' }
}

/**
 * This dictionary contains urls that are outside our project
 *
 */
export const externalPaths = {
         referenceLaboratory: {
           path: 'http://lare.fleury.com.br/Pages/default.aspx',
           name: 'Laboratório de Referência'
         },
         genomica: { path: 'https://www.fleurygenomica.com.br/', name: 'Genômica' },
         felippeMattosoExamResultsClient: {
           path: 'https://resultados.fmattoso.com.br/Cliente/',
           name: 'Resultado de exames'
         },
         felippeMattosoExamResultsDoctor: {
           path: 'https://resultados.fmattoso.com.br/medico/',
           name: 'Resultado de exames'
         },
         weinmannExamResultsClient: {
           path: 'https://resultados.weinmann.com.br/Cliente/',
           name: 'Resultado de exames'
         },
         weinmannExamResultsDoctor: {
           path: 'https://resultados.weinmann.com.br/medico/',
           name: 'Resultado de exames'
         },
         fleuryExamResultsClient: {
           path: 'https://resultados.fleury.com.br/Cliente/',
           name: 'Resultado de exames'
         },
         fleuryExamResultsDoctor: {
           path: 'https://resultados.fleury.com.br/medico/',
           name: 'Resultado de exames'
         },
         research: {
           path: 'https://www.fleury.com.br/medico/noticias/fleury-med-podcast',
           name: 'Pesquisa e desenvolvimento'
         },
         calculator: {
           path: 'https://www.fleury.com.br/medico/calculadora',
           name: 'Calculadora Bussulfano'
         },
         maisservicos: [
           {
             path: 'https://maisservicos.fleury.com.br/',
             brand: 'fleury',
             name: 'Fale Conosco'
           },
           {
             path: 'https://maisservicos.fmattoso.com.br/',
             brand: 'felippe-mattoso',
             name: 'Fale Conosco'
           },
           {
             path: 'https://maisservicos.weinmann.com.br/',
             brand: 'weinmann',
             name: 'Fale Conosco'
           }
         ]
       }

interface Path {
  canonical: 'client' | 'doctor'
  clientUrl?: string
  doctorUrl?: string
}

export const getCanonicalUrl = (siteUrl: string, path: Path, detailSlug: string = '') => {
  switch (path.canonical) {
    case 'client':
      return normalizeUrl(`${siteUrl}${path.clientUrl}/${detailSlug}`)
    case 'doctor':
      return normalizeUrl(`${siteUrl}${path.doctorUrl}/${detailSlug}`)
    default:
      return ''
  }
}

export const getNonCanonicalPath = (path: Path) => {
  switch (path.canonical) {
    case 'client':
      if (path.doctorUrl) {
        return path.doctorUrl
      }
      return ''
    case 'doctor':
      if (path.clientUrl) {
        return path.clientUrl
      }
      return ''
    default:
      return ''
  }
}
const createClientDoctorPath = (canonical: 'client' | 'doctor', clientUrl, name) => ({
  name,
  path: {
    canonical,
    clientUrl,
    doctorUrl: clientUrl && insertDoctorPrefix(clientUrl)
  }
})
const createUniquePath = (urlKind: 'client' | 'doctor', uniqueUrl: string, name: string) => ({
  name,
  path: {
    canonical: urlKind,
    clientUrl: urlKind === 'client' && uniqueUrl,
    doctorUrl: urlKind === 'doctor' && insertDoctorPrefix(uniqueUrl)
  }
})

export const getPathUrls = (path: Path) => {
  const urls: string[] = []
  if (path.clientUrl) {
    urls.push(path.clientUrl)
  }
  if (path.doctorUrl) {
    urls.push(path.doctorUrl)
  }
  return urls
}

export const getAllPathsUrls = (paths: typeof appPaths) => {
  const urls: string[] = []
  return Object.keys(paths).reduce((acc, currKey) => {
    return acc.concat(getPathUrls(paths[currKey].path))
  }, urls)
}

export const getPathUrl = (path: Path, isDoctor: boolean) => {
  if (isDoctor) {
    return path.doctorUrl
  }
  return path.clientUrl
}

export const getNonCanonicalUrls = (paths: typeof appPaths) => {
  const nonCanonicalUrls = [] as string[]
  Object.keys(paths).forEach(key => {
    const nonCanonicalPath = getNonCanonicalPath(paths[key].path)
    if (nonCanonicalPath) {
      nonCanonicalUrls.push(nonCanonicalPath)
      nonCanonicalUrls.push(`${nonCanonicalPath}/*`)
    }
  })
  return nonCanonicalUrls
}

export const getUnitDetailPageUrl = (pathUrl: string, unitName: string) => {
  return getDetailPageUrl(pathUrl, diactriclessKebabCase(unitName))
}

/**
 * This dictionary contains other URLS (not listed on pagesPath, neither externalPaths)
 * Some urls are coupled with how cosmicjs is setup (therefore, some changes there might break the code here)
 *
 * ps: every path should start with `/` prefix
 *
 */
export const appPaths = {
  history: createClientDoctorPath('client', '/historia', 'História'),
  otherServices: createClientDoctorPath('client', '/outros-servicos/', 'Outros Serviços'),
  campaignReceptive: createClientDoctorPath(
    'client',
    '/receptivos-de-campanhas',
    'Receptivo de Campanha'
  ),
  // SEM FALE CONOSCO
  // contact: createClientDoctorPath('client', '', 'Fale Conosco'),
  contact: createClientDoctorPath('client', '/fale-conosco', 'Fale Conosco'),
  convenio: createClientDoctorPath('client', '/convenios', 'Convênios'),
  search: createClientDoctorPath('client', '/busca/', 'Busca'),
  differentiatedServices: createClientDoctorPath(
    'client',
    '/servicos-diferenciados',
    'Serviços Diferenciados'
  ),
  diseaseManual: createClientDoctorPath('client', '/manual-de-doencas', 'Manual de Doenças'),
  faq: createClientDoctorPath('client', '/perguntas-frequentes', 'Perguntas Frequentes'),
  news: createClientDoctorPath('client', '/noticias', 'Notícias'),
  lps: createClientDoctorPath('client', '/lps', 'Landing Page'),
  units: createClientDoctorPath('client', '/unidades', 'Unidades'),
  domicileCollect: createClientDoctorPath(
    'client',
    `${
      process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann'
        ? '/coleta-domiciliar'
        : '/noticias/coleta-domiciliar'
    }`,
    'Coleta domiciliar'
  ),
  ourSpecialists: createClientDoctorPath('client', '/corpo-clinico', 'Corpo Clínico'),
  institutional: createClientDoctorPath('client', '/institucional', 'Instiucional'),
  about: createClientDoctorPath('client', '/institucional', 'Sobre'),
  aboutSerdil: createClientDoctorPath('client', '/serdil', 'Serdil'),
  medicalMagazines: createUniquePath('doctor', '/revistas-medicas', 'Revistas médicas'),
  medicalEducation: createUniquePath('doctor', '/fleury-med', 'Fleury Med'),
  doctorRegister: createUniquePath('doctor', '/cadastro-medico', 'Cadastro médico'),
  examManual: createUniquePath('doctor', '/exames/', 'Manual de Exames'),
  scientificArticles: createUniquePath('doctor', '/artigos-cientificos', 'Artigos Ciêntificos'),
  diagnosticManual: createUniquePath('doctor', '/manuais-diagnosticos', 'Manual Diagnósticos'),
  diagnosticScript: createUniquePath('doctor', '/roteiros-diagnosticos', 'Roteiros Diagnósticos'),
  exams: createUniquePath('client', '/exames/', 'Exames'),
  vaccines: createUniquePath('client', '/vacinas/', 'Vacinas'),
  fleuryMagazines: createUniquePath('client', '/revistas-fleury', 'Revistas Fleury'),
  fleuryDayClinic: createClientDoctorPath(
    'client',
    'https://dayclinic.fleury.com.br/',
    'Fleury Day Clinic'
  ),
  privacy: createClientDoctorPath('client', '/portal-de-privacidade', 'Portal de Privacidade'),
  invasion: createClientDoctorPath(
    'client',
    '/comunicado-de-privacidade',
    'Comunicado de Privacidade'
  ),
  areaMedica: createClientDoctorPath('doctor', '/conheca-a-area-medica', 'Área Médica'), //NOVO
  homeCollection: createClientDoctorPath('doctor', '/coleta-domiciliar', 'Coleta Domiciliar'), //NOVO

  medicinaFetal: createClientDoctorPath(
    'doctor',
    '/servicos-diferenciados/medicina-fetal',
    'Medicina Fetal'
  ),
  fleuryFertilidade: createClientDoctorPath(
    'client',
    '/outros-servicos/fleury-fertilidade',
    'Fleury Fertilidade'
  ),
  calculator: createUniquePath('doctor', '/calculadora', 'Calculadora Bussulfano')
}

export const appPathsDictionary = Object.keys(appPaths).reduce((acc, curr) => {
  const item = appPaths[curr]
  const path = item.path as Path
  if (path.clientUrl) {
    acc[path.clientUrl] = item.name
  }
  if (path.doctorUrl) {
    acc[path.doctorUrl] = item.name
  }
  return acc
}, {})

export const pagesPathsDictionary = Object.keys(pagesPaths).reduce((acc, curr) => {
  const item = pagesPaths[curr]
  acc[item.path] = item.name
  return acc
}, {})
