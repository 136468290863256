import { Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import styled, { css } from 'styled-components'

export interface TypographyProps {
  kind?: 'light' | 'default'
}

export interface HDisplayProps extends TypographyProps {
  center?: boolean
}

export const HDisplayStyle = css<HDisplayProps>`
  color: ${props => (props.kind === 'light' ? Color.White : Color.GrayXDark)};
  font-size: ${FontSize.XLarge};
  line-height: 1.5;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
  text-align: ${props => (props.center ? 'center' : 'inherit')};

  :after {
    display: block;
    content: '';
    width: 65px;
    height: 4px;
    background: ${props => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
    margin: ${Spacing.Small} ${props => (props.center ? 'auto' : '0 ')} ${Spacing.Medium};
  }
`

export const HDisplay = styled.h3`
  ${HDisplayStyle}
`
HDisplay.displayName = 'HDisplay'

export const H1Style = css`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Large};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
  line-height: 1.5;
`
export const H1 = styled.h1`
  ${H1Style}
`
export const H1StyleWainmann = css`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.XLarge};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
  line-height: 1.5;
`
export const H1w = styled.h1`
  ${H1StyleWainmann}
`
export interface H2TypographyProps extends TypographyProps {
  cell?: boolean
}

export const H2Style = css<H2TypographyProps>`
  color: ${props => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
  font-size: ${FontSize.Medium};
  letter-spacing: 1px;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: ${props => (props.cell ? 0 : Spacing.Large)};
  margin-bottom: ${props => (props.cell ? 0 : Spacing.Small)};

  &.H2-agendar-exames {
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }
`
export const H2 = styled.h2`
  ${H2Style}
`
H2.displayName = 'H2'

export const H2StyleW = css`
  font-family: Signika;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #373a3a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
export const H2w = styled.p`
  ${H2StyleW}
`
H2w.displayName = 'H2w'

export interface H3TypographyProps extends TypographyProps {
  cell?: boolean
}

export const H3Height = FontSize.Medium
export const H3Style = css`
  color: ${(props: H3TypographyProps) => (props.kind === 'light' ? Color.White : Color.GrayXDark)};
  line-height: 1.5;
  font-size: ${H3Height};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Large)};
  margin-bottom: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Medium)};

  &.title-modal-agendar-exames{
    font-size: 24px;
    margin-top: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Medium)};
    margin-bottom: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Small)};
  }
  
  &.subtitle-modal-agendar-exames{
    line-height: 20px;
  }
`
export const H3 = styled.h3`
  ${H3Style}
`
H3.displayName = 'H3'

export interface H4Props extends TypographyProps {
  /** whether this h4 is being used inside a cell */
  cell?: boolean
  bottomLine?: boolean
}

export const H4Height = FontSize.XSmall
export const H4Style = css<H4Props>`
  color: ${props => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
  line-height: 1.5;
  font-size: ${H4Height};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${props => (props.cell ? 0 : Spacing.Small)};
  margin-bottom: ${props => (props.cell ? 0 : Spacing.XSmall)};
  ${props =>
    props.bottomLine
      ? `:after {
    display: block;
    content: '';
    width: 40px;
    height: 2px;
    background: ${Color.White};
    margin: ${Spacing.XSmall} 0 ;
  }`
      : ''};
`
export const H4 = styled.h4`
  ${H4Style}
`
H4.displayName = 'H4'

export const H4StyleW = css<H4Props>`
  color: ${(props) => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
  line-height: 1.5;
  font-size: ${H4Height};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin-top: ${(props) => (props.cell ? 0 : Spacing.Small)};
  margin-bottom: ${(props) => (props.cell ? 0 : Spacing.XSmall)};
`
export const H4w = styled.h4`
  ${H4StyleW}
  font-size: 12px;
`
H4w.displayName = 'H4w'

export const H5Style = css`
  color: ${(props) => (props.kind === 'light' ? Color.White : props.theme.color.primary)};
  line-height: 1;
  font-size: 12px;
  text-justify: auto;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  //margin-top: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Large)};
  //margin-bottom: ${(props: H3TypographyProps) => (props.cell ? '0px' : Spacing.Medium)};
  
`
export const H5 = styled.h5`
  ${H5Style}
`
H5.displayName = 'H5'

export const BodyStyle = css`
  color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : '#302A38')};
  margin: 0;
  font-size: ${FontSize.Small};
  line-height: 1.5;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Lighter};

  &.newSubtitle{
    color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : '#666666')};
    font-weight: ${FontWeight.Normal};
  }

  &.list-exames-units-detail {
    font-weight: unset;
    font-size: ${FontSize.XSmall};

  }

  &.address-units-detail{
    font-size: ${FontSize.XXSmall};
    text-decoration: underline;
    margin: 20px 0 5px 0;
    color: #302A38;
  }

  @media (min-width: 768px) {
    &.address-units-detail{
      font-size: ${FontSize.Small};
    }
    &.list-exames-units-detail {
      font-size: ${FontSize.Small};

    }

  }
`

const BodyDiseaseContentStyle = styled.h2`
  color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : '#302A38')};
  margin: 0;
  font-size: ${FontSize.Small};
  line-height: 1.5;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Lighter};

  &.newSubtitle{
    color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : '#666666')};
    font-weight: ${FontWeight.Normal};
  }

  &.list-exames-units-detail {
    font-weight: unset;
    font-size: ${FontSize.XSmall};

  }

  &.address-units-detail{
    font-size: ${FontSize.XXSmall};
    text-decoration: underline;
    margin: 20px 0 5px 0;
    color: #302A38;
  }

  @media (min-width: 768px) {
    &.address-units-detail{
      font-size: ${FontSize.Small};
    }
    &.list-exames-units-detail {
      font-size: ${FontSize.Small};

    }

  }
`
export const Body = styled.p`
  ${BodyStyle}
`

export const BodyDiseaseContent = styled.p`
  ${BodyDiseaseContentStyle}
`
Body.displayName = 'Body'

export const BodyStyleW = css`
  color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : Color.Black)};
  margin: 0;
  font-size: ${FontSize.Small};
  line-height: 1.5;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Lighter};
`
export const BodyW = styled.p`
  ${BodyStyleW}
`
BodyW.displayName = 'BodyW'

export const DivStileCont = css`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`
export const DivCont = styled.div`
  ${DivStileCont}
`
DivCont.displayName = 'DivCont'

export const DivStile = css`
  margin: 0;
`
export const DivW = styled.p`
  ${DivStile}
`
DivW.displayName = 'DivW'

export const DivStileR = css`
  display: flex;
  align-items: flex-start;
`
export const DivWR = styled.div`
  ${DivStileR}
`
DivWR.displayName = 'DivWR'

export const DivStileR1 = css`
  padding: 5px;
  width: 210px;
`
export const DivWR1 = styled.div`
  ${DivStileR1}
`
DivWR.displayName = 'DivWR'

export const DivStileTW = css`
  color: #373a3a;
  font-size: 32px;
  font-weight: 600;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`
export const DivTW = styled.p`
  ${DivStileTW}
`
DivTW.displayName = 'DivTW'

export const DivContainer = css`
  color: #373a3a;
`
export const DivC = styled.p`
  ${DivContainer}
`
DivC.displayName = 'DivC'

export const bodySecondaryLineHeight = 1.5
export const bodySecondaryFontSize = FontSize.XSmall
export const bodySecondaryFontSizeNew = FontSize.XXSmall

export const BodySecondary = styled.label`
  color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : Color.GrayDark)};
  font-size: ${bodySecondaryFontSize};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  line-height: ${bodySecondaryLineHeight};
  margin: 0;
  &.weinmann{
    font-size: ${bodySecondaryFontSizeNew};
  }
`

export const BodySecondaryW = styled.label`
  color: ${(props: TypographyProps) => (props.kind === 'light' ? Color.White : Color.GrayDark)};
  font-size: ${bodySecondaryFontSize};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  line-height: ${bodySecondaryLineHeight};
  margin: 0;

  background: #e6f3f5;
  border-radius: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
`

export const SemiBold = styled.span`
  font-weight: ${FontWeight.SemiBold};
`

export const BodySecondaryCentered = styled(BodySecondary)`
  text-align: center;
`

export const DT = styled.dt`
  display: inline;
  color: ${Color.GrayXDark};
  line-height: 1.5;
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
`

export const DD = styled.dd`
  display: inline;
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Small};
  line-height: 1.5;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
`

interface InputLabelProps {
  hasError?: boolean
}
export const InputLabel = styled.label`
  color: ${(props: InputLabelProps) => (props.hasError ? Color.GrayDark : Color.GrayDark)};
  display: block;
  font-size: ${FontSize.XXSmall};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  margin-left: 0;
  margin-bottom: 0;
`

export const InputValueCss = css`
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
`

export const InputValue = styled.label`
  ${InputValueCss};
`

export const InputDisabledCss = css`
  color: ${Color.Gray};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${Color.Gray};
  -webkit-opacity: 1;
`

export const InputDisabled = styled.label`
  ${InputDisabledCss};
`

export const InputCaption = styled.label`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.XSmall};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.XSmall};
  display: block;
`

export const InputCaptionError = styled(InputCaption)`
  margin-top: ${Spacing.Small};
  margin-left: 0;
  color: ${props => props.theme.color.alert};
  font-size: ${FontSize.XXSmall};
`

export const InputPlaceholderCss = css`
  color: ${(props: { light?: boolean }) => (props.light ? Color.White : Color.GrayDark)};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Normal};
`

export const InputPlaceholder = styled.label`
  pointer-events: none;
  ${InputPlaceholderCss};
`

export const ProductPricePrefix = styled.span`
  color: ${Color.GrayDark};
  font-weight: ${FontWeight.Bold};
`

export interface PriceProps {
  isFeatured?: boolean
}

export const ProductPrice = styled.label<{ isFeatured: boolean }>`
  color: ${props => (props.isFeatured ? props.theme.color.alert : props.theme.color.primary)};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
`

export const ProductOldPrice = styled.label`
  color: ${Color.GrayDark};
  text-decoration: line-through;
  font-size: ${FontSize.XSmall};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.Lighter};
`
//Modal Agendar exame
export const WhatsappRow = styled.span`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`

// Utilities

export const Ellipsed = styled.span`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
